'use strict'

const uniq_ = require('lodash/uniq')
const get_ = require('lodash/get')
const mapValues_ = require('lodash/mapValues')
const groupBy_ = require('lodash/groupBy')
const has_ = require('lodash/has')
const flow_ = require('lodash/flow')
const isNull_ = require('lodash/isNull')
const Maybe = require('folktale/maybe')

const DATASET_TYPES = require('@wix/dbsm-common/src/datasetTypes')
const {
  UploadButton: uploadButtonSdkType
} = require('@wix/dbsm-common/src/componentTypes')
const { SCOPE_TYPES } = require('@wix/dbsm-common/src/scopes/consts')

const rootReducer = require('./rootReducer')
const recordActions = require('../records/actions')
const dynamicPagesActions = require('../dynamic-pages/actions')
const configActions = require('../dataset-config/actions')
const rootActions = require('./actions')

const configureDatasetStore = require('./configureStore')
const {
  setDependencies,
  waitForDependencies,
  performHandshake,
  resolveMissingDependencies
} = require('../dependency-resolution/actions')
const datasetApiCreator = require('../dataset-api/datasetApi')
const eventListenersCreator = require('../dataset-events/eventListeners')
const syncComponentsWithState = require('../side-effects/syncComponentsWithState')
const getFieldType = require('../schemas/getFieldType')
const {
  findConnectedComponents,
  resolveHandshakes,
  setConnectedComponents
} = require('../connected-components')
const {
  adapterApiCreator,
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts,
  initAdapters
} = require('../components')
const {
  createFilterResolver,
  createValueResolvers,
  hasDatabindingDependencies,
  getDatabindingDependencyIds
} = require('../filter-resolvers')
const dependenciesManagerCreator = require('../dependency-resolution/dependenciesManager')
const { isSameRecord, createRecordStoreInstance } = require('../record-store')

const { reportDatasetActiveOnPage } = require('../bi/events')

const rootSubscriber = require('./rootSubscriber')
const dynamicPagesSubscriber = require('../dynamic-pages/subscriber')
const createSiblingDynamicPageUrlGetter = require('../dynamic-pages/siblingDynamicPageGetterFactory')
const seedDataFetcher = require('./seed')
const { traceCreators } = require('../logger')

const waitForControllerDependencies = store => {
  const filter = rootReducer.getFilter(store.getState())

  if (!hasDatabindingDependencies(filter)) {
    return
  }

  const dependenciesIds = getDatabindingDependencyIds(filter)
  store.dispatch(setDependencies(dependenciesIds))
  return waitForDependencies(store)
}

const onChangeHandler = (getState, dispatch, adapterApi, logger) => {
  const areArgumentsIllegal = (before, after) =>
    isNull_(before) && isNull_(after)
  const recordWasAdded = (before, after) => isNull_(before)
  const recordWasDeleted = (before, after) => isNull_(after)
  const currentRecordWasChanged = (changedRecord, currentRecord) =>
    isSameRecord(changedRecord, currentRecord)

  return (before, after, componentIdToExclude) => {
    const argsAreIllegal = areArgumentsIllegal(before, after)
    if (argsAreIllegal) {
      logger.error(
        new Error('onChangeHandler invoked with illegal arguments'),
        { extra: { arguments: { before, after, componentIdToExclude } } }
      )
      return
    }

    if (recordWasAdded(before, after)) {
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    const currentRecord = rootReducer.selectCurrentRecord(getState())

    if (recordWasDeleted(before, after)) {
      if (isSameRecord(before, currentRecord)) {
        dispatch(recordActions.refreshCurrentRecord()).catch(() => {})
      }
      dispatch(recordActions.refreshCurrentView()).catch(() => {})
      return
    }

    if (currentRecordWasChanged(before, currentRecord)) {
      const currentRecordIndex = rootReducer.selectCurrentRecordIndex(
        getState()
      )

      dispatch(
        recordActions.setCurrentRecord(
          after,
          currentRecordIndex,
          componentIdToExclude
        )
      ).catch(() => {})
    }
  }
}

function waitForAllChildControllersToBeReady(controllerStore) {
  return Promise.all(
    controllerStore.getAll().map(
      scope =>
        new Promise(resolve => {
          scope.staticExports.onReady(resolve)
        })
    )
  )
}

const createDataset = (controllerFactory, controllerStore) => (
  isScoped,
  isFixedItem,
  {
    controllerConfig,
    datasetType,
    connections,
    wixDataProxy,
    wixSdk,
    firePlatformEvent,
    errorReporter,
    verboseReporter,
    platformUtilities,
    routerData,
    appLogger,
    datasetId,
    handshakes = [],
    schemaAPI,
    recordStoreService,
    reportFormEventToAutomation,
    instansiateDatabindingVerboseReporter,
    parentId
  }
) => {
  const unsubscribeHandlers = []
  const eventListeners = eventListenersCreator(
    firePlatformEvent,
    errorReporter,
    verboseReporter
  )

  const { prefetchedData, dynamicPagesData } = routerData || {}

  const { fireEvent } = eventListeners
  unsubscribeHandlers.push(eventListeners.dispose)

  const { store, subscribe, onIdle } = configureDatasetStore(
    appLogger,
    datasetId
  )

  unsubscribeHandlers.push(
    appLogger.addSessionData(() => ({
      [datasetId]: {
        datasetType,
        state: store.getState(),
        connections
      }
    }))
  )

  store.dispatch(
    rootActions.init({
      controllerConfig,
      connections,
      isScoped
    })
  )

  const datasetCollectionName = rootReducer.getCollectionName(store.getState())
  unsubscribeHandlers.push(
    appLogger.addSessionData(() => ({
      scopes: controllerStore.getAll()
    }))
  )

  const dependenciesManager = dependenciesManagerCreator()
  unsubscribeHandlers.push(dependenciesManager.unsubscribe)

  const dependenciesPromise = waitForControllerDependencies(store)

  const getSchema = (schemaName = datasetCollectionName) => {
    return Maybe.fromNullable(schemaAPI.getSchema(schemaName))
  }

  const getFieldTypeFunc = fieldName => {
    const schema = getSchema(datasetCollectionName)
    const referencedCollectionsSchemas = schemaAPI.getReferencedCollectionsSchemas(
      datasetCollectionName
    )
    return schema.chain(s =>
      Maybe.fromNullable(
        getFieldType(s, referencedCollectionsSchemas)(fieldName)
      )
    )
  }

  const valueResolvers = createValueResolvers(dependenciesManager.get(), wixSdk)
  const filterResolver = createFilterResolver(valueResolvers)

  const recordStore = createRecordStoreInstance({
    recordStoreService,
    getFilter: flow_(_ => store.getState(), rootReducer.getFilter),
    getSort: flow_(_ => store.getState(), rootReducer.getSort),
    getPageSize: flow_(_ => store.getState(), rootReducer.getCurrentPageSize),
    shouldAllowWixDataAccess: flow_(
      _ => store.getState(),
      rootReducer.shouldAllowWixDataAccess
    ),
    prefetchedData,
    datasetId,
    filterResolver,
    getSchema
  })

  const shouldLinkNextPrevDynamicPageComponents =
    !isScoped && datasetType === DATASET_TYPES.ROUTER_DATASET

  const siblingDynamicPageUrlGetter = shouldLinkNextPrevDynamicPageComponents
    ? createSiblingDynamicPageUrlGetter({
        wixDataProxy,
        dynamicPagesData,
        collectionName: datasetCollectionName
      })
    : null

  if (shouldLinkNextPrevDynamicPageComponents) {
    subscribe(dynamicPagesSubscriber(siblingDynamicPageUrlGetter))
    store.dispatch(dynamicPagesActions.initialize(connections))
  }

  const datasetApi = datasetApiCreator({
    store,
    recordStore,
    logger: appLogger,
    eventListeners,
    handshakes,
    controllerStore,
    errorReporter,
    verboseReporter,
    datasetId,
    datasetType,
    isFixedItem,
    siblingDynamicPageUrlGetter,
    dependenciesManager,
    onIdle
  })

  const uniqueRoles = uniq_(connections.map(conn => conn.role))
  const appDatasetApi = datasetApi(false)
  const componentAdapterContexts = []
  const databindingVerboseReporter = instansiateDatabindingVerboseReporter(
    datasetCollectionName,
    parentId
  )
  const adapterParams = {
    getState: store.getState,
    datasetApi: appDatasetApi,
    wixSdk,
    errorReporter,
    platformUtilities,
    eventListeners,
    roles: uniqueRoles,
    getFieldType: getFieldTypeFunc,
    getSchema,
    wixDataProxy,
    appLogger,
    applicationCodeZone: appLogger.applicationCodeZone,
    controllerFactory,
    controllerStore,
    databindingVerboseReporter,
    parentId
  }
  const adapterApi = adapterApiCreator({
    dispatch: store.dispatch,
    recordStore,
    componentAdapterContexts
  })

  unsubscribeHandlers.push(
    recordStoreService
      .map(service =>
        service.onChange(
          onChangeHandler(store.getState, store.dispatch, adapterApi, appLogger)
        )
      )
      .getOrElse(() => {})
  )

  const hasPrefetchedData = !!prefetchedData

  const shouldFetchInitialData =
    controllerConfig && !rootReducer.isWriteOnly(store.getState())

  const fetchInitialData = () =>
    shouldFetchInitialData
      ? seedDataFetcher(
          hasPrefetchedData,
          recordStore,
          errorReporter,
          appLogger
        )
      : Promise.resolve(Maybe.Nothing())

  const fetchInitialDataPromise = dependenciesPromise
    ? dependenciesPromise.then(fetchInitialData)
    : fetchInitialData()

  fetchInitialDataPromise.then(maybeRecord =>
    maybeRecord.map(record =>
      store.dispatch(recordActions.setCurrentRecord(record, 0))
    )
  )

  handshakes.forEach(handshake =>
    performHandshake(dependenciesManager, store.dispatch, handshake)
  )

  const shouldRefreshDataset = () => {
    const isWriteOnly = rootReducer.isWriteOnly(store.getState())
    const currentRecordIndex = rootReducer.selectCurrentRecordIndex(
      store.getState()
    )
    const isPristine = recordStore().matchWith({
      Error: () => false,
      Ok: ({ value: service }) => service.isPristine(currentRecordIndex)
    })

    return isPristine && !isWriteOnly
  }

  const pageReady = async function($w) {
    wixSdk.user.onLogin(() => {
      // THIS SHOULD HAPPEN SYNCHRONOUSLY SO TESTS WILL REMAIN MEANINGFUL
      // IF YOU EVER FIND THE NEED TO MAKE IT ASYNC - TALK TO leeor@wix.com
      if (shouldRefreshDataset()) {
        appDatasetApi.refresh()
      }
    })

    const connectedComponents = findConnectedComponents(uniqueRoles, $w)
    store.dispatch(
      setConnectedComponents(
        connectedComponents.map(({ component }) => component.uniqueId)
      )
    )

    // THIS SHOULD HAPPEN SYNCHRONOUSLY AFTER PAGE READY IS CALLED TO KEEP CONTROLLERS RUNNING SEQUENCE
    const controllersToHandshake = resolveHandshakes({
      datasetApi: appDatasetApi,
      components: connectedComponents,
      controllerConfig
    })
    controllersToHandshake.forEach(({ controller, handshakeInfo }) =>
      controller.handshake(handshakeInfo)
    )

    if (dependenciesPromise) {
      // if by now we are still waiting for dependencies, mark them as resolved
      // since they are guaranteed to perform a handshake with us before our pageReady.
      // A missing dependency can happen in a master-detail scenario where the user
      // deleted the master dataset
      store.dispatch(resolveMissingDependencies())

      await dependenciesPromise
    }

    const dependencies = dependenciesManager.get()

    // scoped datasets are sure to have the schema resolved and therefore don't have to wait
    if (!isScoped) {
      await schemaAPI.waitForSchemas()
    }

    componentAdapterContexts.push(
      ...createComponentAdapterContexts({
        connectedComponents,
        $w,
        adapterApi,
        getFieldType: getFieldTypeFunc,
        ignoreItemsInRepeater: !isScoped,
        dependencies,
        adapterParams
      })
    )

    if (!isScoped) {
      const detailsRepeatersAdapterContexts = createDetailsRepeatersAdapterContexts(
        connectedComponents,
        getFieldTypeFunc,
        dependencies,
        adapterParams
      )
      componentAdapterContexts.push(...detailsRepeatersAdapterContexts)
    }

    subscribe(
      rootSubscriber(
        recordStore,
        adapterApi,
        getFieldTypeFunc,
        eventListeners.executeHooks,
        appLogger,
        datasetId,
        componentAdapterContexts,
        getSchema,
        datasetCollectionName,
        reportFormEventToAutomation,
        fireEvent,
        verboseReporter
      )
    )

    unsubscribeHandlers.push(
      addComponentDataToExceptions(
        componentAdapterContexts,
        appLogger,
        datasetId
      )
    )

    unsubscribeHandlers.push(
      syncComponentsWithState(
        store,
        componentAdapterContexts,
        appLogger,
        datasetId,
        recordStore
      )
    )

    const defaultRecord = getDefaultRecord(componentAdapterContexts)
    store.dispatch(recordActions.setDefaultRecord(defaultRecord))
    if (
      rootReducer.isDatasetConfigured(store.getState()) &&
      rootReducer.isWriteOnly(store.getState())
    ) {
      await store.dispatch(recordActions.initWriteOnly(isScoped))
    }

    return fetchInitialDataPromise.then(async () => {
      try {
        reportDatasetActiveOnPage(
          appLogger.bi,
          store.getState(),
          connections,
          datasetType,
          isScoped,
          datasetId,
          wixSdk
        )
      } catch (err) {
        appLogger.error(err)
      }
      await initAdapters(adapterApi())
      if (!isScoped) {
        await waitForAllChildControllersToBeReady(controllerStore)
      }
      store.dispatch(configActions.setIsDatasetReady(true))
      fireEvent('datasetReady')
      return get_(wixSdk, ['window', 'rendering', 'env']) === 'backend'
        ? {
            schemas: await schemaAPI.waitForSchemas(),
            store: recordStore().matchWith({
              Error: () => null,
              Ok: ({ value: service }) => service.getTheStore()
            })
          }
        : undefined
    })
  }

  const userCodeDatasetApi = datasetApi(true)
  const dynamicExports = (scope /*, $w*/) => {
    switch (scope.type) {
      case SCOPE_TYPES.COMPONENT:
        return userCodeDatasetApi.inScope(
          scope.compId,
          scope.additionalData.itemId
        )
      default:
        return userCodeDatasetApi
    }
  }

  const dispose = () => {
    componentAdapterContexts.splice(0)
    unsubscribeHandlers.forEach(h => h())
  }

  const finalPageReady = isScoped
    ? pageReady
    : $w =>
        appLogger.traceAsync(
          traceCreators.pageReady(),
          traceCreators.errorToTraceOptions,
          () => pageReady($w)
        )

  return {
    pageReady: appLogger.applicationCodeZone(finalPageReady),
    exports: dynamicExports,
    staticExports: userCodeDatasetApi,
    dispose
  }
}

const getDefaultRecord = componentAdapterContexts => {
  const inputComponentsProps = ['value', 'checked'] //todo: export to constant
  return Object.assign(
    {},
    ...componentAdapterContexts
      .filter(({ componentType }) => componentType !== uploadButtonSdkType)
      .map(({ component, connectionConfig: { properties } }) =>
        Object.assign(
          {},
          ...inputComponentsProps.map(
            propName =>
              has_(properties, propName)
                ? { [properties[propName].fieldName]: component[propName] }
                : undefined
          )
        )
      )
  )
}

const addComponentDataToExceptions = (
  componentAdapterContexts,
  logger,
  datasetId
) => {
  const componentIdToRole = mapValues_(
    groupBy_(componentAdapterContexts, cac => cac.componentId),
    cacArray => cacArray.map(cac => cac.role).join()
  )

  return logger.addSessionData(() => ({
    [datasetId]: {
      components: componentIdToRole
    }
  }))
}

module.exports = createDataset
