'use strict'

const {
  traceHandlerIds: { FEDOPS, MONITORING_HUB, SYSTEM_TRACING }
} = require('./loggerWithHandlers')

const traceLevels = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error'
}

const initAppForPage = () => ({
  actionName: 'databinding/initAppForPage',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
  }
})

const createControllers = () => ({
  actionName: 'databinding/createControllers',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
  }
})

const loadSchemas = () => ({
  actionName: 'databinding/loadSchemas',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
  }
})

const findRecords = ({ collectionName, filter, sort, offset, length }) => ({
  actionName: 'dataset/findRecords',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [MONITORING_HUB, SYSTEM_TRACING],
    data: {
      message: {
        collectionName,
        filter,
        sort,
        offset,
        length
      }
    }
  }
})

const pageReady = () => ({
  actionName: 'dataset/pageReady',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
  }
})
const pageReadyGetData = () => ({
  actionName: 'dataset/pageReady/getData',
  options: {
    level: traceLevels.INFO,
    reportToHandlers: [FEDOPS, MONITORING_HUB, SYSTEM_TRACING]
  }
})

const repeaterItemReady = index => ({
  actionName: `connectedRepeaterAdapter itemReady ${index}`,
  options: { level: traceLevels.INFO, reportToHandlers: [SYSTEM_TRACING] }
})

const repeaterSetData = () => ({
  actionName: 'connectedRepeaterAdapter setting repeater.data',
  options: { level: traceLevels.INFO, reportToHandlers: [SYSTEM_TRACING] }
})

const repeaterRecordSetLoaded = () => ({
  actionName: `connectedRepeaterAdapter recordSetLoaded`,
  options: { level: traceLevels.INFO, reportToHandlers: [SYSTEM_TRACING] }
})

const repeaterCurrentViewChanged = () => ({
  actionName: `connectedRepeaterAdapter currentViewChanged`,
  options: { level: traceLevels.INFO, reportToHandlers: [SYSTEM_TRACING] }
})

const errorToTraceOptions = error => ({
  level: traceLevels.ERROR,
  data: { message: error }
})

module.exports.errorToTraceOptions = errorToTraceOptions
module.exports.initAppForPage = initAppForPage
module.exports.createControllers = createControllers
module.exports.findRecords = findRecords
module.exports.loadSchemas = loadSchemas
module.exports.pageReady = pageReady
module.exports.pageReadyGetData = pageReadyGetData
module.exports.repeaterItemReady = repeaterItemReady
module.exports.repeaterSetData = repeaterSetData
module.exports.repeaterRecordSetLoaded = repeaterRecordSetLoaded
module.exports.repeaterCurrentViewChanged = repeaterCurrentViewChanged
