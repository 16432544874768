'use strict'

const addComponentEventListener = require('../addComponentEventListener')
const { selectCurrentRecord } = require('../../dataset-controller/rootReducer')
const { traceCreators } = require('../../logger')

const adapter = ({
  appLogger,
  getState,
  controllerFactory,
  controllerStore,
  applicationCodeZone,
  databindingVerboseReporter
}) => {
  const itemReadyPromises = []

  const itemReady = (compId, compUniqueId) => (scoped$w, itemData, index) => {
    const traceId = appLogger.traceStart(traceCreators.repeaterItemReady(index))
    const scopeId = { componentId: compUniqueId, itemId: itemData._id }
    const controller = controllerFactory.createFixedItemController(
      scopeId,
      itemData,
      compId
    )
    controllerStore.setController(scopeId, controller)
    const pageReadyPromise = controller.pageReady(scoped$w.scoped)
    itemReadyPromises.push(pageReadyPromise)
    appLogger.traceEnd({ traceId })
  }

  const itemRemoved = compUniqueId => itemData => {
    const scopeId = { componentId: compUniqueId, itemId: itemData._id }
    controllerStore.removeController(scopeId)
  }

  const refreshView = traceAction => async ({ component }, actions) => {
    const traceId = appLogger.traceStart(traceAction)
    const { items } = await actions.fetchCurrentItems(getState())

    databindingVerboseReporter.logValue({
      component,
      valueDescription: { data: items }
    })
    const dataTraceId = appLogger.traceStart(traceCreators.repeaterSetData())
    component.data = items
    appLogger.traceEnd({ traceId: dataTraceId })
    await Promise.all(itemReadyPromises)
    itemReadyPromises.splice(0)
    appLogger.traceEnd({ traceId })
  }

  return {
    isValidContext({ connectionConfig }) {
      return true
    },

    bindToComponent({ component }, actions, api) {
      const { id: compId, uniqueId: compUniqueId } = component
      addComponentEventListener(
        component,
        'onItemReady',
        itemReady(compId, compUniqueId),
        applicationCodeZone
      )
      addComponentEventListener(
        component,
        'onItemRemoved',
        itemRemoved(compUniqueId),
        applicationCodeZone
      )

      databindingVerboseReporter.logBinding({ component })
    },

    currentRecordModified({ component }, actions, updatedFields) {
      const updatedItem = selectCurrentRecord(getState())
      if (component.data && component.data.length > 0) {
        const existingItems = component.data

        const newItems = existingItems.map(
          existingItem =>
            existingItem._id === updatedItem._id ? updatedItem : existingItem
        )
        component.data = newItems
      }
    },

    recordSetLoaded: refreshView(traceCreators.repeaterRecordSetLoaded()),

    currentViewChanged: refreshView(traceCreators.repeaterCurrentViewChanged()),

    currentIndexChanged(componentAdapterContext, actions, isNewRecord) {}
  }
}

module.exports = adapter
