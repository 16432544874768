'use strict'

const { union } = require('folktale/adt/union')
const noop_ = require('lodash/noop')
const merge_ = require('lodash/merge')
const {
  DBSMViewer,
  UserErrors,
  WixData
} = require('@wix/dbsm-common/src/raven/apps')
const setupRaven = require('../raven/setupRaven')
const {
  errorBoundaryScopes: { USER_SCOPE, WIX_DATA_SCOPE, APPLICATION_SCOPE }
} = require('../error-boundaries/errorBoundaries')

const ravenHandlerCreator = ({
  global,
  appName,
  options,
  createClients
}) => () => {
  const { Raven, UserRaven, WixDataRaven } = createClients()

  setupRaven(Raven, global, DBSMViewer.dsn, options, appName)
  setupRaven(UserRaven, global, UserErrors.dsn, options, appName)
  setupRaven(WixDataRaven, global, WixData.dsn, options, appName)

  const zoneToRavenMap = {
    [APPLICATION_SCOPE]: Raven,
    [USER_SCOPE]: UserRaven,
    [WIX_DATA_SCOPE]: WixDataRaven
  }

  const createReportOptions = ({ level, sessionData, options = {} }) => {
    return merge_({ level }, { extra: sessionData }, options)
  }

  return {
    init: ({ user }) => {
      ;[Raven, UserRaven, WixDataRaven].forEach(raven => {
        raven.setUserContext(user)
      })
    },
    log: logEvent => {
      logEvent.matchWith({
        Breadcrumb: ({ breadcrumb }) => Raven.captureBreadcrumb(breadcrumb),
        Info: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'info', sessionData, options })
          )
        },
        Warn: ({ message, options, sessionData }) => {
          Raven.captureMessage(
            message,
            createReportOptions({ level: 'warning', sessionData, options })
          )
        },
        Error: ({ error, options, sessionData }) =>
          zoneToRavenMap[options.zone].captureException(
            error,
            createReportOptions({ sessionData, options })
          ),
        [union.any]: noop_
      })
    }
  }
}

module.exports.ravenHandlerCreator = ravenHandlerCreator
