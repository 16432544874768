'use strict'

const noop_ = require('lodash/noop')
const Result = require('folktale/result')
const { union } = require('folktale/adt/union')
const { filterByReportToHandlers } = require('./filterByReportToHandlers')

const id = 'SYSTEM_TRACING'

const Environment = union('Environment', {
  NotInitialized() {},
  Initialized({ reportTrace }) {
    return { reportTrace }
  }
})

function doTrace(reportTrace, params) {
  Result.try(() => reportTrace(params))
}

const systemTracingHandlerCreator = () => {
  let environment = Environment.NotInitialized()

  const systemTracingHandler = () => ({
    init: ({ reportTrace }) => {
      environment = Environment.Initialized({ reportTrace })
    },
    log: filterByReportToHandlers(id, logEvent => {
      environment.matchWith({
        Initialized: ({ reportTrace }) => {
          logEvent.matchWith({
            TraceStart: ({ actionName, startOptions }) => {
              doTrace(
                reportTrace,
                Object.assign(
                  { actionName, tracePosition: 'before' },
                  startOptions.data
                )
              )
            },
            TraceEnd: ({
              actionName,
              endOptions,
              durationMs: actionDurationMs
            }) => {
              doTrace(
                reportTrace,
                Object.assign(
                  { actionName, tracePosition: 'after', actionDurationMs },
                  endOptions.data
                )
              )
            },
            [union.any]: noop_
          })
        },
        NotInitialized: () => {
          throw new Error(
            `You cannot report to system tracer before setting the logger environment.
              Make sure you call logger.init before reporting.`
          )
        }
      })
    })
  })

  return systemTracingHandler
}

module.exports.id = id
module.exports.systemTracingHandlerCreator = systemTracingHandlerCreator
