const { createStore: createReduxStore, applyMiddleware } = require('redux')
const {
  createLoggerReduxMiddleware
} = require('@wix/dbsm-common/src/errors/loggerReduxMiddleware')
const reduxSerialEffects = require('redux-serial-effects')
const rootReducer = require('./rootReducer')
const createBreadcrumbMessage = require('../helpers/createBreadcrumbMessage')

const configureStore = (logger, datasetId) => {
  const {
    middleware: serialEffectsMiddleware,
    subscribe,
    onIdle
  } = reduxSerialEffects.createMiddleware()

  const store = createReduxStore(
    rootReducer,
    undefined,
    applyMiddleware(
      serialEffectsMiddleware,
      createLoggerReduxMiddleware(logger, breadCrumb =>
        Object.assign({}, breadCrumb, {
          message: createBreadcrumbMessage(breadCrumb.message, datasetId)
        })
      )
    )
  )

  return {
    store,
    subscribe,
    onIdle
  }
}

module.exports = configureStore
