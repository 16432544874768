'use strict'

const { union } = require('folktale/adt/union')

const filterByReportToHandlers = (id, logFn) => logEvent => {
  logEvent.matchWith({
    TraceStart: ({ startOptions }) => {
      if (startOptions.reportToHandlers.includes(id)) {
        logFn(logEvent)
      }
    },
    TraceEnd: ({ startOptions }) => {
      if (startOptions.reportToHandlers.includes(id)) {
        logFn(logEvent)
      }
    },
    [union.any]: () => logFn(logEvent)
  })
}

module.exports.filterByReportToHandlers = filterByReportToHandlers
