'use strict'
const { selectCurrentRecord } = require('../../dataset-controller/rootReducer')
const getFieldValue = require('../../helpers/getFieldValue')
const isRecord = require('../../helpers/isRecord')
const {
  isFieldFromReferencedCollection,
  getReferenceFieldName
} = require('@wix/dbsm-common/src/reference-fields/fieldPath')
const { FIELD_TYPES } = require('@wix/dbsm-common/src/collections/consts')

const values_ = require('lodash/values')
const isEmpty_ = require('lodash/isEmpty')
const forEach_ = require('lodash/forEach')
const includes_ = require('lodash/includes')
const get_ = require('lodash/get')

const defaultLocation = {
  latitude: 37.77065,
  longitude: -122.387301,
  description: 'Wix Office'
}

module.exports = ({
  getState,
  errorReporter,
  getFieldType,
  databindingVerboseReporter
}) => {
  const logVerboseForBinding = (component, connectionConfig) => {
    const { properties } = connectionConfig
    const bindingDescription = {}

    forEach_(properties, ({ fieldName }, propName) => {
      bindingDescription[propName] = fieldName
    })
    databindingVerboseReporter.logBinding({
      component,
      bindingDescription
    })
  }

  const logVerboseValueDescription = (component, properties, record) => {
    const valueDescription = {}

    forEach_(properties, ({ fieldName }, propPath) => {
      valueDescription[propPath] = getFieldValue(record, fieldName)
    })

    databindingVerboseReporter.logValue({
      component,
      valueDescription
    })
  }

  const transformValueToProp = value => {
    return get_(value, 'location') && get_(value, 'formatted')
      ? {
          latitude: value.location.latitude,
          longitude: value.location.longitude,
          description: value.formatted
        }
      : value === undefined
        ? defaultLocation
        : value
  }

  const isFieldExists = (updatedFields, fieldName) =>
    includes_(
      updatedFields,
      isFieldFromReferencedCollection(fieldName)
        ? getReferenceFieldName(fieldName)
        : fieldName
    )

  const updateComponentFromCurrentRecord = (
    { connectionConfig: { properties }, component },
    updatedFields = []
  ) => {
    const record = selectCurrentRecord(getState())
    if (!record) {
      return
    }
    logVerboseValueDescription(component, properties, record)

    forEach_(properties, ({ fieldName }, propName) => {
      try {
        if (
          isEmpty_(updatedFields) ||
          isFieldExists(updatedFields, fieldName)
        ) {
          const newValue = getFieldValue(record, fieldName)
          const fieldType = getFieldType(fieldName).getOrElse('')

          const valueToSet =
            fieldType === FIELD_TYPES.REFERENCE && isRecord(newValue)
              ? newValue._id
              : newValue

          component[propName] = transformValueToProp(valueToSet)
        }
      } catch (e) {
        errorReporter(`Failed setting ${propName}:`, e)
      }
    })
  }

  return {
    isValidContext({ connectionConfig }) {
      return values_(connectionConfig).find(
        configValue => !isEmpty_(configValue)
      )
    },
    bindToComponent({ connectionConfig, component }) {
      logVerboseForBinding(component, connectionConfig)
    },
    currentRecordModified(componentAdapterContext, actions, updatedFields) {
      updateComponentFromCurrentRecord(componentAdapterContext, updatedFields)
    },
    recordSetLoaded(componentAdapterContext) {
      updateComponentFromCurrentRecord(componentAdapterContext)
    },
    currentViewChanged(componentAdapterContext) {
      updateComponentFromCurrentRecord(componentAdapterContext)
    },
    currentIndexChanged(componentAdapterContext) {
      updateComponentFromCurrentRecord(componentAdapterContext)
    }
  }
}
